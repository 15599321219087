import { FC } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";

interface CancerData {
  name: string;
  localized: number;
  metastatic: number;
}

interface MobileAccordionProps {
  data: CancerData[];
}

export const MobileAccordion: FC<MobileAccordionProps> = ({ data }) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  return (
    <div className="space-y-4">
      {data.map((item, index) => (
        <motion.div
          key={item.name}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="bg-white rounded-xl shadow-sm border border-blue-100"
        >
          <button
            onClick={() =>
              setExpandedIndex(expandedIndex === index ? null : index)
            }
            className="w-full text-left px-4 py-3 flex justify-between items-center"
          >
            <span className="font-medium text-blue-950">{item.name}</span>
            <motion.span
              animate={{ rotate: expandedIndex === index ? 180 : 0 }}
              transition={{ duration: 0.3 }}
              className="text-blue-600"
            >
              ▼
            </motion.span>
          </button>

          <AnimatePresence>
            {expandedIndex === index && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="overflow-hidden"
              >
                <div className="px-4 pb-4 space-y-3">
                  <div>
                    <div className="flex justify-between text-sm mb-1">
                      <span className="text-blue-900/70">Localisé</span>
                      <span className="font-medium text-blue-950">
                        {item.localized}%
                      </span>
                    </div>
                    <div className="h-2 bg-blue-100 rounded-full overflow-hidden">
                      <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: `${item.localized}%` }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="h-full bg-blue-600 rounded-full"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="flex justify-between text-sm mb-1">
                      <span className="text-blue-900/70">Métastatique</span>
                      <span className="font-medium text-blue-950">
                        {item.metastatic}%
                      </span>
                    </div>
                    <div className="h-2 bg-blue-100 rounded-full overflow-hidden">
                      <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: `${item.metastatic}%` }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="h-full bg-blue-300 rounded-full"
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      ))}
    </div>
  );
};
