import { FC } from "react";
import { motion } from "framer-motion";
import { Background } from "../Hero/components/Background";
import { GradientOrbs } from "../Hero/components/GradientOrbs";

const ScreeningHero: FC = () => {
  return (
    <section className="relative min-h-[70vh] w-full bg-white overflow-hidden desktop:pt-32 laptop:pt-28 tablet:pt-24 mobile:pt-20">
      <Background />
      <GradientOrbs />
      <div className="container mx-auto px-6 max-w-7xl desktop:py-20 laptop:py-16 tablet:py-12 mobile:py-10 relative z-10">
        <div className="desktop:flex laptop:flex tablet:block mobile:block items-center gap-12">
          <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl font-bold text-blue-950 mb-8 leading-tight"
            >
              Assurez-vous contre le cancer !
            </motion.h1>
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-2xl text-blue-600 mb-8"
            >
              Faites un dépistage complet
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="desktop:text-xl laptop:text-lg tablet:text-base mobile:text-base text-blue-900/70 mb-6"
            >
              Détecter un cancer à un stade précoce peut faire toute la
              différence.
            </motion.p>{" "}
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="desktop:text-xl laptop:text-lg tablet:text-base mobile:text-base text-blue-900/70"
            >
              Le dépister tôt démultiplie vos chances de guérison en augmentant
              l'efficacité des réponses thérapeutiques.
            </motion.p>
          </div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
            className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full desktop:mt-0 laptop:mt-0 tablet:mt-8 mobile:mt-8"
          >
            <img
              src="https://createsiteai.b-cdn.net/c/_/8342dcfd-ffd6-427f-ae01-40e8252e756b"
              alt="Advanced Screening Equipment"
              className="w-full h-[600px] object-cover rounded-2xl shadow-lg"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ScreeningHero;
