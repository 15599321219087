import { motion } from "framer-motion";
import { FC } from "react";
import { AIBadge } from "./AIBadge";
import { MainImage } from "./MainImage";
import { StatsCard } from "./StatsCard";

export const RightContent: FC = () => (
  <motion.div
    initial={{ opacity: 0, x: 40 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 1, delay: 0.3 }}
    className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full tablet:flex tablet:justify-center mobile:flex mobile:justify-center 
             desktop:mt-0 laptop:mt-0 tablet:mt-20 mobile:mt-16"
  >
    <div className="relative">
      <MainImage />
      <StatsCard />
      <AIBadge />
    </div>
  </motion.div>
);
