import { FC } from "react";
import ScreeningHero from "../components/ScreeningHero";
import ScreeningSteps from "../components/ScreeningSteps";
import Footer from "../components/Footer";
import Products from "../components/Products";
import Quote from "../components/Quote";
import { motion } from "framer-motion";

const Screening: FC = () => {
  return (
    <>
      <ScreeningHero />
      <ScreeningSteps />
      <Products />
      <Quote />
      <Footer />
    </>
  );
};

export default Screening;
