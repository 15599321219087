import { motion } from "framer-motion";
import { FC } from "react";

export const AIBadge: FC = () => (
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: 1 }}
    className="absolute -left-8 top-1/3 bg-white/90 px-6 py-4 rounded-2xl shadow-lg backdrop-blur-sm border border-blue-50"
  >
    <div className="flex items-center gap-4">
      <div className="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center">
        <svg
          className="w-6 h-6 text-blue-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      </div>
      <div>
        <p className="text-blue-950 font-semibold">150+ Biomarqueurs</p>
        <p className="text-blue-600/70 text-sm">Analyses complètes</p>
      </div>
    </div>
  </motion.div>
);
