import { FC } from "react";
import { ProductCard } from "./components/ProductCard";
import { motion } from "framer-motion";

export const Products: FC = () => {
  const products = [
    {
      title: "Neok 1.3 - Détection Précoce du Cancer",
      detectionRate: "Dépiste 75% des cas de cancers",
      description:
        "Le test Neok 1 a pour ambition de dépister les cancers naissants avant la phase métastatique, lorsqu'ils sont les plus curables.",
      features: [
        "Taux de détection: 75% des cancers",
        "Dépistage avant phase métastatique",
        "Optimal pour traitement précoce",
      ],
      periodicity: "Périodicité du test :",
      periodicityDetails: "• À renouveler chaque année",
      price: "1 248€ TTC",
      isAvailable: true,
    },
    {
      title: "Programme Prédisposition Neok",
      detectionRate: "92 gènes de prédisposition",
      description:
        "Les scientifiques ont identifié 92 gènes qui expliquent la grande majorité des prédispositions génétiques.",
      features: [
        "Analyse ADN approfondie",
        "Haute précision d'analyse",
        "Détection des prédispositions génétiques",
        "Pour personnes à risque identifié",
        "Consultation OncoGénétique en cas de présence de gène de prédisposition",
      ],
      periodicity: "Périodicité du test :",
      periodicityDetails:
        "• Test à effectuer une seule fois au cours de la vie",
      price: "Bientôt disponible",
      isAvailable: false,
    },
  ];

  return (
    <section className="relative w-full bg-white py-24">
      <div className="container mx-auto px-6">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-blue-950 mb-6 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
            Nos panels
          </h2>
        </motion.div>
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {products.map((product, index) => (
            <ProductCard key={index} product={product} index={index} />
          ))}
        </div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.3 }}
          className="flex flex-col items-center mt-12"
        >
          <motion.a
            href="https://neok.app/sign-in#register"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-flex items-center justify-center px-8 py-4 text-lg font-medium text-white rounded-2xl 
                bg-gradient-to-r from-blue-600 to-blue-400 
                hover:from-blue-700 hover:to-blue-500 
                transition-all duration-300 shadow-lg shadow-blue-500/25
                hover:shadow-xl hover:shadow-blue-600/30"
          >
            Démarrer mon dépistage
            <svg
              className="w-5 h-5 ml-2 -mr-1 transition-transform group-hover:translate-x-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </motion.a>
          <motion.p
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="text-blue-900/70 text-sm mt-4"
          >
            Évaluation du risque d'être porteur d'un cancer naissant
          </motion.p>
        </motion.div>
      </div>
    </section>
  );
};

export default Products;
