import { motion } from "framer-motion";
import { FC } from "react";

export const MainImage: FC = () => (
  <motion.div
    initial={{ scale: 0.95, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    transition={{ duration: 1 }}
    className="rounded-3xl overflow-hidden shadow-2xl relative"
  >
    <img
      src="https://createsiteai.b-cdn.net/c/_/92150b50-0ba6-4a22-bf8e-283078a14e9a"
      alt="Neok Screening Technology"
      className="w-full object-cover desktop:h-[600px] laptop:h-[500px] tablet:h-[400px] mobile:h-[300px]"
    />
    <div className="absolute inset-0 bg-gradient-to-t from-blue-900/30 to-transparent"></div>
  </motion.div>
);
