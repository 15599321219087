import { FC } from "react";
import { motion } from "framer-motion";

export const WaveAnimation: FC = () => {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {[...Array(3)].map((_, index) => (
        <motion.div
          key={index}
          className="absolute inset-0 border-t-2 border-blue-100/20"
          initial={{ y: index * 100 }}
          animate={{
            y: [index * 100, (index + 1) * 100, index * 100],
          }}
          transition={{
            duration: 5 + index,
            repeat: Infinity,
            ease: "easeInOut",
            delay: index * 0.5,
          }}
          style={{
            transform: `rotate(${index * 3}deg)`,
          }}
        />
      ))}
    </div>
  );
};
