import { FC } from "react";
import { motion } from "framer-motion";
import { cancerTypes } from "../data/cancerTypes";

export const ScreenedCancers: FC = () => {
  return (
    <section className="relative w-full py-24">
      <div className="absolute inset-0 z-0">
        <img
          src="https://createsiteai.b-cdn.net/c/_/51a168c7-641a-4bc8-a525-dddf35945eb3"
          alt="Cancer Research Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-br from-blue-50/90 to-white/90 backdrop-blur-sm" />
      </div>

      <div className="container mx-auto px-4 max-w-7xl relative z-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h2 className="desktop:text-6xl laptop:text-5xl tablet:text-4xl mobile:text-3xl font-bold text-blue-950 mb-8 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
            Cancers Dépistés
          </h2>
          <p className="text-blue-900/70 max-w-2xl mx-auto text-lg">
            Notre panel complet analyse de multiples biomarqueurs cliniques
            (symptômes, facteurs de risque, antécédents…) et biologiques pour
            une détection personnalisée.
          </p>
        </motion.div>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-6">
          {cancerTypes.map((cancer, index) => (
            <motion.div
              key={cancer.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="group relative"
            >
              <div className="relative bg-white/80 backdrop-blur-sm rounded-xl p-6 border border-blue-100 hover:border-blue-200 transition-all duration-300 shadow-sm hover:shadow-md">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-xl font-bold text-blue-950">
                    {cancer.name}
                  </h3>
                  <span className="text-sm text-blue-600 bg-blue-50 px-2 py-1 rounded-full">
                    {cancer.percentageCases}
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-blue-50/50 rounded-lg p-3">
                    <div className="text-2xl font-bold text-blue-600">
                      {cancer.bloodMarkers}
                    </div>
                    <div className="text-sm text-blue-900/70">
                      Biomarqueurs sanguins
                    </div>
                  </div>
                  <div className="bg-blue-50/50 rounded-lg p-3">
                    <div className="text-2xl font-bold text-blue-600">
                      {cancer.clinicalMarkers}
                    </div>
                    <div className="text-sm text-blue-900/70">
                      Biomarqueurs cliniques
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};
