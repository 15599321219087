import { motion } from "framer-motion";
import { FC } from "react";

const partnerLogos = [
  "https://createsiteai.b-cdn.net/c/_/b4dba395-b5ab-404a-b246-31f16a4448ef",
  "https://createsiteai.b-cdn.net/c/_/9c4f169e-f37b-4e46-81cd-df4c7114c93d",
  "https://createsiteai.b-cdn.net/c/_/93204601-a951-4127-969e-bef2663990bf",
  "https://createsiteai.b-cdn.net/c/_/22c19d95-f6e9-4865-a8bf-4806a9f30f5e",
  "https://createsiteai.b-cdn.net/c/_/564d6656-4a42-4835-a76a-c0a4b404a611",
  "https://createsiteai.b-cdn.net/c/_/67394012-e6cb-4c42-86bc-ab3d807bf7dc",
  "https://createsiteai.b-cdn.net/c/_/ddae9cb9-4b5f-4892-9761-334912431e22",
];

const Sponsors: FC = () => {
  return (
    <section className="relative w-full bg-white py-24">
      <div className="container mx-auto px-6 max-w-7xl">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-blue-950 mb-6">
            Partenaires de Confiance
          </h2>
          <p className="text-blue-900/70 max-w-2xl mx-auto">
            Neok est soutenue par des institutions de santé et centres de
            recherche de premier plan
          </p>
        </motion.div>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-2 gap-12">
          {partnerLogos.map((logo, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className={`flex items-center justify-center ${
                index === partnerLogos.length - 1 &&
                partnerLogos.length % 3 !== 0
                  ? "desktop:col-span-3 laptop:col-span-3 tablet:col-span-2 mobile:col-span-2 desktop:w-1/3 laptop:w-1/3 tablet:w-1/2 mobile:w-1/2 mx-auto"
                  : ""
              }`}
            >
              <img
                src={logo}
                alt={`Partner ${index + 1}`}
                className="max-w-full h-auto opacity-60 hover:opacity-100 transition-opacity duration-300 filter grayscale hover:grayscale-0"
              />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sponsors;
