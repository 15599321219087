import { FC, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { HiX } from "react-icons/hi";

export const MovemberBanner: FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          className="fixed bottom-0 left-0 right-0 bg-gray-100 border-t border-gray-200 z-50 desktop:block laptop:block tablet:block mobile:hidden"
        >
          <div className="container mx-auto px-6 py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-6">
                <img
                  src="https://createsiteai.b-cdn.net/c/_/746b57f0-1f31-437c-aef0-0da16cfac8c9"
                  alt="Movember Logo"
                  className="h-12 w-auto"
                />
                <div>
                  <h3 className="font-bold text-blue-950 text-lg">
                    Nous soutenons Movember, et vous ?
                  </h3>
                  <p className="text-blue-900/70 text-sm">
                    Agissez contre le cancer de la prostate !
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-6">
                <a
                  href="https://fr.movember.com/donate"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
                >
                  Faire un don
                </a>
                <button
                  onClick={() => setIsVisible(false)}
                  className="text-gray-400 hover:text-gray-600 transition-colors"
                >
                  <HiX className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
