import { FC } from "react";
import { MobileAccordion } from "./components/MobileAccordion";
import { motion } from "framer-motion";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const data = [
  {
    name: "Prostate",
    localized: 100,
    metastatic: 34,
  },
  {
    name: "Sein",
    localized: 99,
    metastatic: 31,
  },
  {
    name: "Côlon et rectum",
    localized: 91,
    metastatic: 16,
  },
  {
    name: "Poumon et bronches",
    localized: 63,
    metastatic: 8,
  },
  {
    name: "Pancréas",
    localized: 44,
    metastatic: 3,
  },
];

export const SurvivalRatesChart: FC = () => {
  return (
    <section className="relative w-full bg-white py-16">
      <div className="container mx-auto px-6">
        {" "}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-blue-950 mb-6">
            Exemples de taux de survie pour certains cancers
          </h2>
          <p className="text-blue-900/70 max-w-2xl mx-auto">
            Comparaison entre les stades de cancer localisé (stade 1-2) et
            disséminé (stade 3/4)
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto bg-blue-50/50 rounded-3xl p-8 border border-blue-100"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="w-full desktop:h-[400px] laptop:h-[350px] tablet:h-[300px] mobile:hidden"
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 60,
                }}
                barGap={0}
                barCategoryGap={30}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#e2e8f0" />
                <XAxis
                  dataKey="name"
                  tick={{ fill: "#1e3a8a", fontSize: 12 }}
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                />
                <YAxis
                  tick={{ fill: "#1e3a8a", fontSize: 12 }}
                  domain={[0, 100]}
                />
                <Tooltip
                  formatter={(value: number) => `${value}%`}
                  contentStyle={{
                    background: "linear-gradient(to right, #dbeafe, #eff6ff)",
                    border: "1px solid #bfdbfe",
                    borderRadius: "0.5rem",
                    padding: "0.75rem",
                    boxShadow: "0 4px 6px -1px rgb(59 130 246 / 0.1)",
                    transition: "all 0.3s ease",
                  }}
                  cursor={{
                    fill: "#dbeafe",
                    opacity: 0.3,
                    transition: "opacity 0.3s ease",
                  }}
                />
                <Bar
                  dataKey="localized"
                  fill="#3b82f6"
                  radius={[4, 4, 0, 0]}
                  name="Localisé"
                >
                  <LabelList
                    dataKey="localized"
                    position="top"
                    formatter={(value: number) => `${value}%`}
                    style={{ fontSize: "12px", fill: "#1e3a8a" }}
                  />
                </Bar>
                <Bar
                  dataKey="metastatic"
                  fill="#93c5fd"
                  radius={[4, 4, 0, 0]}
                  name="Métastatique"
                >
                  <LabelList
                    dataKey="metastatic"
                    position="top"
                    formatter={(value: number) => `${value}%`}
                    style={{ fontSize: "12px", fill: "#1e3a8a" }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </motion.div>
          <div className="desktop:hidden laptop:hidden tablet:hidden mobile:block">
            <MobileAccordion data={data} />
          </div>

          <div className="flex justify-center gap-8 mt-4">
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-blue-500 rounded"></div>
              <span className="text-sm text-blue-900/70">Localisé</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-blue-300 rounded"></div>
              <span className="text-sm text-blue-900/70">Métastatique</span>
            </div>
          </div>
          <div className="text-sm text-gray-500 text-center mt-4">
            Source: American Cancer Society
          </div>
        </motion.div>
      </div>
    </section>
  );
};
