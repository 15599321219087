import { motion } from "framer-motion";
import { FC } from "react";

interface Feature {
  number: string;
  title: string;
  description: string;
  list?: string[];
  logos?: string[];
}

interface FeatureCardProps {
  feature: Feature;
  index: number;
}

export const FeatureCard: FC<FeatureCardProps> = ({ feature, index }) => (
  <motion.div
    key={index}
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ delay: index * 0.2 }}
    className="bg-blue-50/50 rounded-2xl p-5 backdrop-blur-sm"
  >
    <div className="flex items-baseline gap-4 mb-2">
      <span className="text-4xl font-bold text-blue-600">{feature.number}</span>
      <span className="text-xl font-semibold text-blue-950">
        {feature.title}
      </span>
    </div>
    <p className="text-blue-900/70 mb-4">{feature.description}</p>
    {feature.list && (
      <div className="flex flex-wrap gap-2 mt-4">
        {feature.list.map((item, i) => (
          <span
            key={i}
            className="bg-white px-3 py-1 rounded-full text-sm text-blue-600 transition-all duration-300 hover:bg-blue-50 hover:shadow-md hover:scale-105 cursor-default"
          >
            {item}
          </span>
        ))}
      </div>
    )}
    {feature.logos && (
      <div className="flex justify-around gap-4 mt-6">
        {feature.logos.map((logo, i) => (
          <motion.img
            key={i}
            src={logo}
            alt={`Logo ${i + 1}`}
            className={`h-auto object-contain ${feature.number === "4" ? "w-[25%]" : "w-[50%]"}`}
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 + i * 0.1 }}
          />
        ))}
      </div>
    )}
  </motion.div>
);
