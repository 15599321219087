import { motion } from "framer-motion";
import { FC } from "react";
import { Badge } from "./Badge";
import { CTAButtons } from "./CTAButtons";
import { Heading } from "./Heading";

export const LeftContent: FC = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.8 }}
    className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full tablet:text-center mobile:text-center"
  >
    <Heading />
    <div className="mb-12">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-2 gap-6 mobile:gap-3"
      >
        <div className="flex items-center gap-3 bg-white desktop:p-3 laptop:p-3 tablet:p-3 mobile:p-2 rounded-xl shadow-sm">
          <svg
            className="desktop:w-5 laptop:w-5 tablet:w-5 mobile:w-4 desktop:h-5 laptop:h-5 tablet:h-5 mobile:h-4 text-blue-600 mt-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
            />
          </svg>
          <div>
            <h4 className="font-semibold text-blue-950 desktop:text-base laptop:text-base tablet:text-base mobile:text-xs">
              Expertise Médicale
            </h4>
            <p className="desktop:text-sm laptop:text-sm tablet:text-sm mobile:text-[10px] text-blue-900/70">
              Équipe spécialisée
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3 bg-white desktop:p-4 laptop:p-4 tablet:p-4 mobile:p-2 rounded-xl shadow-sm">
          <svg
            className="desktop:w-6 laptop:w-6 tablet:w-6 mobile:w-4 desktop:h-6 laptop:h-6 tablet:h-6 mobile:h-4 text-blue-600 mt-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
          <div>
            <h4 className="font-semibold text-blue-950 desktop:text-base laptop:text-base tablet:text-base mobile:text-xs">
              Parcours Sur-mesure
            </h4>
            <p className="desktop:text-sm laptop:text-sm tablet:text-sm mobile:text-[10px] text-blue-900/70">
              Suivi personnalisé
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3 bg-white desktop:p-4 laptop:p-4 tablet:p-4 mobile:p-2 rounded-xl shadow-sm">
          <svg
            className="desktop:w-6 laptop:w-6 tablet:w-6 mobile:w-4 desktop:h-6 laptop:h-6 tablet:h-6 mobile:h-4 text-blue-600 mt-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div>
            <h4 className="font-semibold text-blue-950 desktop:text-base laptop:text-base tablet:text-base mobile:text-xs">
              Détection Précoce
            </h4>
            <p className="desktop:text-sm laptop:text-sm tablet:text-sm mobile:text-[10px] text-blue-900/70">
              Premiers stades
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3 bg-white desktop:p-4 laptop:p-4 tablet:p-4 mobile:p-2 rounded-xl shadow-sm">
          <svg
            className="desktop:w-6 laptop:w-6 tablet:w-6 mobile:w-4 desktop:h-6 laptop:h-6 tablet:h-6 mobile:h-4 text-blue-600 mt-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 10V3L4 14h7v7l9-11h-7z"
            />
          </svg>
          <div>
            <h4 className="font-semibold text-blue-950 desktop:text-base laptop:text-base tablet:text-base mobile:text-xs">
              Résultats Rapides
            </h4>
            <p className="desktop:text-sm laptop:text-sm tablet:text-sm mobile:text-[10px] text-blue-900/70">
              Analyse en 3 semaines
            </p>
          </div>
        </div>
      </motion.div>
    </div>
    <CTAButtons />
  </motion.div>
);
