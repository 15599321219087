import { FC } from "react";
import { AnimatedShapes } from "./AnimatedShapes";
import { WaveAnimation } from "./WaveAnimation";
import { ParallaxEffect } from "./ParallaxEffect";

export const AnimatedBackground: FC = () => {
  return (
    <div className="absolute inset-0 overflow-hidden">
      <ParallaxEffect />
      <WaveAnimation />
      <AnimatedShapes />
    </div>
  );
};
