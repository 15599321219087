import { motion, AnimatePresence } from "framer-motion";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FC, useState } from "react";

const FAQ: FC = () => {
  const [openCategory, setOpenCategory] = useState<string | null>(
    "Service Neok",
  );
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const faqCategories = {
    "Service Neok": [
      {
        question:
          "Quels biomarqueurs sont analysés dans le cadre du test Neok 1.3 ?",
        answer:
          "Neok 1.3 analyse 10 biomarqueurs usuels (CA15-3, CA125, CA 19-9, ACE, AFP, Cytokératine, PSA, NFS, β2-microglobuline, EPS) + 44 autres biomarqueurs spécialisés cancer gardés confidentiels. Seuls les résultats anormaux vous seront détaillés dans le compte-rendu.",
      },
      {
        question:
          "Êtes-vous spécialisés uniquement dans le dépistage du cancer ?",
        answer:
          "Oui pour l'instant, car le cancer reste aujourd'hui la seconde pathologie la plus meurtrière dans le monde avec les plus grandes lacunes dans les méthodes diagnostiques.",
      },
      {
        question: "Comment fonctionne le dépistage ?",
        answer:
          "Une fois inscrit au service Neok, tout se passe via votre espace sécurisé. Vous êtes d'abord invité à répondre à un questionnaire complet sur vos antécédents médicaux et symptômes potentiels. Ensuite, si jugé nécessaire, vous prenez rendez-vous sur la plateforme pour effectuer votre prise de sang. Une fois vos prélèvements analysés, un rapport détaillé vous sera envoyé sur votre espace sécurisé Neok. Celui-ci relève les risques identifiés et les éventuels cancers en développement. En cas de cancer naissant, nous vous orienterons vers un médecin pour un traitement.",
      },
      {
        question: "Les oncologues lisent-ils mes analyses de sang ?",
        answer:
          "Oui ! Une équipe complète d'oncologues analysera vos résultats en cas de doute.",
      },
      {
        question: "Proposez-vous des consultations médicales ?",
        answer:
          "Oui, si vos résultats le nécessitent, vous serez invité à une consultation médicale.",
      },
      {
        question: "Proposer un dépistage à mes salariés ?",
        answer: (
          <>
            Oui, avec toute entreprise désireuse de prendre soin de ses
            collaborateurs. Pour tout renseignement, écrivez-nous à{" "}
            <a
              href="mailto:contact@neok.fr"
              className="text-blue-600 hover:text-blue-700 underline"
            >
              contact@neok.fr
            </a>
            .
          </>
        ),
      },
      {
        question: "Comment puis-je m'abonner au service Neok ?",
        answer: (
          <>
            Vous pouvez rejoindre la liste d'attente :{" "}
            <a
              href="https://neok.app/sign-in#register"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-700 underline"
            >
              https://neok.app/sign-in#register
            </a>
          </>
        ),
      },
      {
        question: "Depuis quel endroit puis-je bénéficier du service Neok ?",
        answer:
          "Dans l'attente de couvrir toute la France métropolitaine, le service Neok est disponible à Paris et proche banlieue.",
      },
      {
        question: "Le suivi est-il inclus dans le service ?",
        answer:
          "Selon les résultats des analyses, un suivi est mis en place pour vous rappeler les examens de prévention à réaliser, les contrôles à effectuer, et, si nécessaire, pour organiser un rendez-vous avec un oncologue.",
      },
    ],
    "Données Neok": [
      {
        question:
          "Mes données pourront-elles être transmises pour alimenter des programmes de recherche ?",
        answer:
          "Seulement si vous nous donnez votre consentement. Notre centre de recherche vise à améliorer les méthodes de dépistage du cancer grâce aux seules données pour lesquelles nous aurons obtenu un accord express d'utilisation.",
      },
      {
        question: "À qui appartiennent mes données personnelles et médicales ?",
        answer:
          "Vous détenez l'entière propriété de vos données de santé. Nous les utilisons uniquement pour faciliter votre parcours de prévention et les analysons dans le respect des plus hautes exigences éthiques. Aucune donnée n'est exploitée à des fins commerciales ni partagée sans votre accord.",
      },
      {
        question: "Comment Neok protège-t-elle mes données médicales ?",
        answer:
          "Chez Neok, la sécurité de vos données est notre priorité. Nous utilisons des technologies de chiffrement robustes et appliquons des techniques avancées et un pseudo pour garantir que vos informations restent inaccessibles à toute personne non autorisée.",
      },
      {
        question: "Où Neok stocke-t-elle mes données de santé ?",
        answer:
          "Vos données de santé sont hébergées en toute sécurité chez OVH, un prestataire certifié Hébergeur de Données de Santé (HDS) situé au sein de l'Espace Économique Européen. Ce choix garantit que vos informations personnelles sont stockées dans des conditions conformes aux normes les plus strictes de la législation.",
      },
      {
        question: "Neok partage-t-elle mes données avec des tiers ?",
        answer:
          "Vos données ne sont jamais vendues ni utilisées à des fins lucratives. Elles ne sont partagées qu'avec les professionnels de santé impliqués dans votre suivi médical et avec nos partenaires techniques, lorsque cela est nécessaire pour vous fournir nos services de dépistage. Chaque partage se fait dans le cadre de protocoles stricts et sécurisés.",
      },
    ],
    Autres: [
      {
        question: "Que signifie Neok ?",
        answer:
          "Neo (naissant / nouveau en grec) + K (signe utilisé par les médecins pour les cancers). Soit Neok, pour cancers naissants. Ce nom résume notre ambition : identifier les cancers naissants grâce à une détection précoce, permettant la multiplication des chances de survie et une prise en charge moins invasive pour les patients.",
      },
      {
        question: "Comment puis-je signaler un problème ?",
        answer: (
          <>
            Pour toute question, vous pouvez nous adresser un email à l'adresse{" "}
            <a
              href="mailto:contact@neok.fr"
              className="text-blue-600 hover:text-blue-700 underline"
            >
              contact@neok.fr
            </a>
            .
          </>
        ),
      },
    ],
  };

  return (
    <section className="relative w-full bg-blue-50/50 py-24">
      <div className="container mx-auto px-6 max-w-7xl">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-blue-950 mb-6">
            Questions Fréquentes
          </h2>
          <p className="text-blue-900/70 max-w-2xl mx-auto">
            Trouvez des réponses aux questions courantes sur notre processus de
            dépistage
          </p>
        </motion.div>

        <div className="max-w-3xl mx-auto px-4 mx-auto">
          <div className="desktop:flex laptop:flex tablet:flex mobile:hidden gap-4 mb-8">
            {Object.keys(faqCategories).map((category) => (
              <motion.button
                key={category}
                onClick={() => setOpenCategory(category)}
                className={`flex-1 px-6 py-3 rounded-xl text-lg font-medium shadow-sm transition-all duration-300 ${
                  openCategory === category
                    ? "bg-gradient-to-r from-blue-600 to-blue-500 text-white shadow-blue-500/25"
                    : "bg-white text-blue-900 hover:bg-blue-50 hover:shadow-md"
                }`}
                whileHover={{ scale: 1.02, y: -2 }}
                whileTap={{ scale: 0.98 }}
              >
                {category}
              </motion.button>
            ))}
          </div>
          <div className="desktop:hidden laptop:hidden tablet:hidden mobile:block mb-8">
            <Menu as="div" className="relative">
              <Menu.Button className="w-full flex items-center justify-between px-6 py-3 bg-white text-blue-900 rounded-xl shadow-sm hover:bg-blue-50 transition-all duration-300">
                <span className="text-lg font-medium">{openCategory}</span>
                <svg
                  className="w-5 h-5 text-blue-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-10 w-full mt-2 origin-top bg-white rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {Object.keys(faqCategories).map((category) => (
                      <Menu.Item key={category}>
                        {({ active }) => (
                          <button
                            onClick={() => setOpenCategory(category)}
                            className={`${
                              active
                                ? "bg-blue-50 text-blue-600"
                                : "text-blue-900"
                            } ${
                              openCategory === category
                                ? "bg-blue-50 text-blue-600"
                                : ""
                            } group flex w-full items-center px-4 py-3 text-left text-lg font-medium transition-colors`}
                          >
                            {category}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          {openCategory &&
            faqCategories[openCategory as keyof typeof faqCategories].map(
              (faq, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="mb-4"
                >
                  <motion.button
                    onClick={() =>
                      setOpenIndex(openIndex === index ? null : index)
                    }
                    className={`w-full text-left p-5 bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 ${
                      openIndex === index
                        ? "border-2 border-blue-100"
                        : "border border-blue-50"
                    }`}
                    whileHover={{ y: -2 }}
                  >
                    <div className="flex justify-between items-center">
                      <h3 className="font-semibold text-blue-950">
                        {faq.question}
                      </h3>
                      <span
                        className={`text-2xl font-medium transition-transform duration-300 ${
                          openIndex === index
                            ? "text-blue-600 rotate-45"
                            : "text-blue-400"
                        }`}
                      >
                        +
                      </span>
                    </div>
                    <AnimatePresence>
                      {openIndex === index && (
                        <motion.div
                          initial={{
                            opacity: 0,
                            height: 0,
                            rotateX: -90,
                            transformOrigin: "top",
                          }}
                          animate={{
                            opacity: 1,
                            height: "auto",
                            rotateX: 0,
                            transition: {
                              height: { duration: 0.4 },
                              opacity: { duration: 0.3 },
                              rotateX: { duration: 0.4 },
                            },
                          }}
                          exit={{
                            opacity: 0,
                            height: 0,
                            rotateX: -90,
                            transition: {
                              height: { duration: 0.4 },
                              opacity: { duration: 0.3 },
                              rotateX: { duration: 0.3 },
                            },
                          }}
                          style={{
                            transformStyle: "preserve-3d",
                            perspective: "1000px",
                          }}
                          className="overflow-hidden backdrop-blur-sm"
                        >
                          <motion.p
                            initial={{ scale: 0.95 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.95 }}
                            transition={{ duration: 0.2 }}
                            className="mt-4 text-blue-900/70 bg-blue-50/30 p-4 rounded-xl"
                          >
                            {faq.answer}
                          </motion.p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.button>
                </motion.div>
              ),
            )}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
