import { motion } from "framer-motion";
import { FC } from "react";

export const Heading: FC = () => (
  <motion.h1
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.4 }}
    className="font-bold mb-6 text-blue-950"
  >
    <span className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl leading-[1.1] block mb-4 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
      Neok :
    </span>
    <span className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl leading-[1.1] block text-blue-950">
      Acteur de prévention et de dépistage en oncologie
    </span>
  </motion.h1>
);
