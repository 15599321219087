import { FC } from "react";
import { Background } from "./components/Background";
import { AnimatedBackground } from "./components/AnimatedBackground";
import { GradientOrbs } from "./components/GradientOrbs";
import { HeroContent } from "./components/HeroContent";
import { StatsSection } from "./components/StatsSection";

const Hero: FC = () => {
  return (
    <section className="relative min-h-screen w-full bg-white overflow-hidden mt-20">
      <Background />
      <AnimatedBackground />
      <GradientOrbs />
      <div className="container mx-auto px-6 max-w-7xl desktop:py-20 laptop:py-16 tablet:py-12 mobile:py-10 relative z-10">
        <div className="desktop:flex laptop:flex tablet:block mobile:block items-center desktop:justify-between laptop:justify-between tablet:justify-center mobile:justify-center gap-12">
          <HeroContent />
        </div>
        <StatsSection />
      </div>
    </section>
  );
};

export default Hero;
