import { motion } from "framer-motion";
import { FC } from "react";
import { features } from "../data/features";
import { FeatureCard } from "./FeatureCard";

export const FeatureList: FC = () => (
  <div className="desktop:flex laptop:flex tablet:block mobile:block items-center gap-12 max-w-6xl mx-auto mb-16">
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full"
    >
      <img
        src="https://createsiteai.b-cdn.net/c/_/f79eb30b-e704-45fd-a93f-6b9b074238b4"
        alt="Digital Twin Visualization"
        className="w-full desktop:h-[400px] laptop:h-[350px] tablet:h-[300px] mobile:h-[250px] object-cover rounded-2xl mb-12"
      />
    </motion.div>

    <motion.div
      initial={{ opacity: 0, x: 20 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full space-y-8"
    >
      {features.map((feature, index) => (
        <FeatureCard key={index} feature={feature} index={index} />
      ))}
    </motion.div>
  </div>
);
