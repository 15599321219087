import { FC } from "react";
import { motion } from "framer-motion";

interface TimelineMilestoneProps {
  milestone: {
    title: string;
    description: string;
    details: string;
  };
  index: number;
}

export const TimelineMilestone: FC<TimelineMilestoneProps> = ({
  milestone,
  index,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8, delay: index * 0.2 }}
      className="group relative p-[1px] rounded-2xl bg-gradient-to-br from-blue-400/20 via-blue-300/20 to-blue-200/20"
    >
      <div className="absolute inset-0 bg-gradient-to-br from-blue-400/20 via-blue-300/20 to-blue-200/20 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

      <div className="relative bg-white/40 backdrop-blur-md rounded-2xl p-8 transition-all duration-300 group-hover:bg-white/50 z-0">
        <div className="mb-6">
          <span className="inline-block text-blue-600 font-bold text-lg px-4 py-1 bg-blue-100/50 backdrop-blur-sm rounded-full">
            0{index + 1}
          </span>
          <h3 className="text-2xl font-bold text-blue-950 mt-4">
            {milestone.title}
          </h3>
          <p className="text-blue-900/70 mt-2">{milestone.description}</p>
        </div>

        <div className="bg-white/50 backdrop-blur-sm p-6 rounded-xl border border-blue-100/50 transition-all duration-300 group-hover:border-blue-200/50">
          <p className="text-blue-900/70">{milestone.details}</p>
        </div>
      </div>
    </motion.div>
  );
};
