import { FC } from "react";
import { motion } from "framer-motion";
import { HiCheck } from "react-icons/hi2";

interface ProductCardProps {
  product: {
    title: string;
    detectionRate: string;
    description: string;
    features: string[];
    periodicity: string;
    periodicityDetails: string;
    price: string;
    isAvailable: boolean;
  };
  index: number;
}

export const ProductCard: FC<ProductCardProps> = ({ product, index }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.2 }}
      className="bg-white rounded-2xl border border-blue-100 hover:border-blue-200 transition-all duration-300 p-6 shadow-sm hover:shadow-md h-full flex flex-col justify-between"
    >
      <div className="mb-8">
        <h3 className="text-2xl font-bold text-blue-950 mb-4">
          {product.title}
        </h3>
        <div className="text-2xl font-bold text-blue-600 mb-6">
          {product.detectionRate}
        </div>
        <p className="text-blue-900/70">{product.description}</p>
      </div>

      <ul className="space-y-3 mb-8">
        {product.features.map((feature, i) => (
          <li key={i} className="flex items-start gap-3">
            <HiCheck className="w-5 h-5 text-blue-600 mt-0.5" />
            <span className="text-blue-900/70">{feature}</span>
          </li>
        ))}
      </ul>

      <div className="mb-8">
        <h4 className="font-semibold text-blue-950 mb-2">
          {product.periodicity}
        </h4>
        <p className="text-blue-900/70">{product.periodicityDetails}</p>
      </div>

      <div className="text-center py-4 border-t border-blue-100">
        <div className="text-2xl font-bold text-blue-600 mb-2">
          {product.price}
        </div>
        {product.price === "1 248€ TTC" && (
          <div className="text-xs text-blue-900/70">
            Non pris en charge par la Sécurité sociale, même pour les individus
            à haut risque
          </div>
        )}
      </div>
    </motion.div>
  );
};
