import { FC } from "react";
import { motion } from "framer-motion";
import Footer from "../components/Footer";

const Legal: FC = () => {
  return (
    <>
      <section className="relative min-h-screen w-full bg-white overflow-hidden desktop:pt-32 laptop:pt-28 tablet:pt-24 mobile:pt-20">
        <div className="container mx-auto px-6 max-w-4xl py-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="prose prose-lg max-w-none"
          >
            <h1 className="text-4xl font-bold text-blue-950 mb-8">
              Mentions Légales
            </h1>

            <h2 className="text-2xl font-semibold text-blue-950 mt-12">
              Éditeur du site
            </h2>
            <p className="text-blue-900/70">
              NEOK SAS
              <br />
              Capital social : 10 000€
              <br />
              RCS Paris B 123 456 789
              <br />
              Siège social : 1 rue de la Santé, 75014 Paris
            </p>

            <h2 className="text-2xl font-semibold text-blue-950 mt-12">
              Hébergement
            </h2>
            <p className="text-blue-900/70">
              OVHcloud
              <br />
              SAS au capital de 10 174 560 €<br />
              RCS Lille Métropole 424 761 419 00045
              <br />
              Siège social : 2 rue Kellermann - 59100 Roubaix - France
            </p>

            <h2 className="text-2xl font-semibold text-blue-950 mt-12">
              Protection des données personnelles
            </h2>
            <p className="text-blue-900/70">
              Conformément à la loi Informatique et Libertés du 6 janvier 1978
              modifiée, vous disposez d'un droit d'accès, de rectification et de
              suppression des données vous concernant. Pour exercer ce droit,
              veuillez nous contacter par email à : contact@neok.fr
            </p>

            <h2 className="text-2xl font-semibold text-blue-950 mt-12">
              Propriété intellectuelle
            </h2>
            <p className="text-blue-900/70">
              L'ensemble du contenu de ce site (structure, textes, logos,
              images, vidéos...) est la propriété exclusive de NEOK SAS. Toute
              reproduction, représentation, modification, publication,
              transmission, dénaturation, totale ou partielle du site ou de son
              contenu, par quelque procédé que ce soit, et sur quelque support
              que ce soit est interdite.
            </p>
          </motion.div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Legal;
