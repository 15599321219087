import { motion } from "framer-motion";
import { FC } from "react";

export const Header: FC = () => (
  <motion.div
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.8 }}
    className="text-center mb-16"
  >
    <h2 className="desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-blue-950 mb-6">
      Mon jumeau numérique Neok
    </h2>
    <p className="text-blue-900/70 max-w-2xl mx-auto">
      Établissez une cartographie oncologique complète
    </p>
  </motion.div>
);
