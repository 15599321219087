import { FC, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const EasterEgg: FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [keySequence, setKeySequence] = useState("");

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const newSequence = keySequence + event.key.toLowerCase();
      setKeySequence(newSequence.slice(-4));
    };

    window.addEventListener("keypress", handleKeyPress);
    return () => window.removeEventListener("keypress", handleKeyPress);
  }, [keySequence]);

  useEffect(() => {
    if (keySequence === "neok") {
      setIsActive(true);
      setTimeout(() => setIsActive(false), 3000);
      setKeySequence("");
    }
  }, [keySequence]);

  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-blue-950/50 backdrop-blur-sm"
        >
          <motion.div
            initial={{ y: 50 }}
            animate={{ y: 0 }}
            exit={{ y: 50 }}
            className="bg-white rounded-2xl p-8 shadow-xl"
          >
            <motion.img
              src="https://createsiteai.b-cdn.net/c/_/94e13579-4863-4bd5-99f4-e25085f6d291"
              alt="NEOK Logo"
              className="h-24 mx-auto mb-4"
              animate={{
                rotate: [0, 360],
                scale: [1, 1.2, 1],
              }}
              transition={{
                duration: 1,
                repeat: 2,
                ease: "easeInOut",
              }}
            />
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="text-xl font-bold text-blue-600 text-center"
            >
              Vous avez découvert notre secret ! 🎉
            </motion.p>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default EasterEgg;
