export const features = [
  {
    number: "110",
    title: "Questions",
    description:
      "Évaluation de vos facteurs de risques personnels et familiaux, et recherche de signes cliniques anormaux.",
  },
  {
    number: "14",
    title: "Cancers",
    description:
      "Si le questionnaire préalable vous analyse comme un sujet à risque, prise de sang annuelle à domicile ou en entreprise par le panel protéomique Neok",
    logos: [
      "https://createsiteai.b-cdn.net/c/_/5f7a16c4-428c-4cac-96f3-ab09458cda22",
    ],
    list: [
      "Sein",
      "Col de l'utérus",
      "Prostate",
      "Colon",
      "Poumons",
      "Lymphomes",
      "Thyroïde",
      "Leucémie",
      "Ovaires",
      "Pancréas",
      "Foie",
      "Myélome",
      "Gastro-intestinal",
      "Œsophage",
    ],
  },
  {
    number: "4",
    title: "Cancers",
    description:
      "Rappel personnalisé des recommandations des dépistages organisés par les campagnes nationales",
    list: ["Sein", "Col de l'utérus", "Prostate", "Colon"],
    logos: [
      "https://createsiteai.b-cdn.net/c/_/9cc5f2b7-86c1-4adb-9cd7-4764e577f989",
      "https://createsiteai.b-cdn.net/c/_/1562e0dc-02eb-4e25-949e-5b0edda2087d",
    ],
  },
];
