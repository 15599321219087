import { FC } from "react";
import { motion } from "framer-motion";

const steps = [
  {
    title: "Étude de risque",
    description:
      "Questionnaire préalable pour identifier vos facteurs de risque de développer un cancer. Si ce risque est significatif, alors vous pourrez participer au programme Neok.",
  },
  {
    title: "Profil de risque",
    description:
      "Questionnaire d'anamnèse médicale complet pour identifier les signes cliniques, révélateurs du développement d'un cancer.",
  },
  {
    title: "Prise de sang",
    description:
      "Échantillon prélevé sur le lieu de votre choix par des infirmiers dédiés, puis analysé par nos partenaires sur différents sites européens.",
  },
  {
    title: "Interprétation médicale",
    description:
      "Analyse du questionnaire et/ou des résultats biologiques par nos oncologues de référence.",
  },
  {
    title: "Rapport d'analyses",
    description:
      "Compte-rendu oncologique complet et détaillé, accessible dans votre espace sécurisé Neok.",
  },
  {
    title: "Suivi personalisé",
    description: "Rappels et accompagnement annuel des actions préventives.",
  },
];

export const StepsContent: FC = () => {
  return (
    <div className="grid desktop:grid-cols-6 laptop:grid-cols-6 tablet:grid-cols-3 mobile:grid-cols-1 gap-8 laptop:gap-4">
      {steps.map((step, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: index * 0.1 }}
          className="text-center bg-white/50 backdrop-blur-sm p-6 laptop:p-4 rounded-xl border border-blue-100 hover:border-blue-200 transition-all duration-300"
        >
          <h3 className="text-xl laptop:text-lg font-bold text-blue-950 mb-3">
            {step.title}
          </h3>
          <p className="text-base laptop:text-sm text-blue-900/70 leading-relaxed">
            {step.description}
          </p>
        </motion.div>
      ))}
    </div>
  );
};
