import { FC } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HiOutlineMenu } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { Fragment } from "react";

interface MobileMenuProps {
  navLinks: {
    path: string;
    label: string;
    isButton?: boolean;
  }[];
}

export const MobileMenu: FC<MobileMenuProps> = ({ navLinks }) => {
  const location = useLocation();

  return (
    <Menu
      as="div"
      className="desktop:hidden laptop:hidden tablet:hidden mobile:block relative"
    >
      <Menu.Button className="p-2 text-blue-950 hover:text-blue-600 transition-colors">
        <HiOutlineMenu className="w-6 h-6" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform translate-x-full opacity-0"
        enterTo="transform translate-x-0 opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="transform translate-x-0 opacity-100"
        leaveTo="transform translate-x-full opacity-0"
      >
        <Menu.Items className="absolute right-0 top-[calc(100%+1rem)] w-64 bg-white/95 backdrop-blur-sm rounded-xl shadow-lg border border-blue-100 p-4 focus:outline-none">
          {navLinks.map((link) => (
            <Menu.Item key={link.path}>
              {({ active }) => (
                <Link
                  to={link.path}
                  className={`block w-full text-left px-4 py-2 text-lg font-medium rounded-lg mb-1 last:mb-0 transition-colors ${
                    link.isButton
                      ? location.pathname === link.path
                        ? "bg-blue-600 text-white"
                        : "bg-blue-100 text-blue-600 hover:bg-blue-200"
                      : active
                        ? "bg-blue-50 text-blue-600"
                        : "text-blue-950 hover:text-blue-600"
                  }`}
                >
                  {link.label}
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
