import { FC, useRef } from "react";
import { motion, useScroll } from "framer-motion";
import { milestones } from "../data/milestones";
import { TimelineMilestone } from "./TimelineMilestone";

export const TimelineContent: FC = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: scrollContainerRef,
    offset: ["start start", "end end"],
  });

  return (
    <div className="desktop:flex laptop:flex tablet:block mobile:block gap-12">
      {/* Sticky left section */}
      <motion.div className="desktop:w-1/3 laptop:w-1/3 tablet:w-full mobile:w-full desktop:sticky laptop:sticky tablet:static mobile:static top-24 h-fit desktop:mb-0 laptop:mb-0 tablet:mb-12 mobile:mb-12">
        <div className="bg-white/50 backdrop-blur-sm p-8 rounded-2xl border border-blue-100 relative z-10">
          <h2 className="desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-blue-950 mb-6 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
            Notre feuille de route
          </h2>
          <p className="text-blue-900/70 mb-8">
            Développer une médecine préventive au faîte de l'état de l'art
            scientifique.
          </p>
          <div className="desktop:block laptop:block tablet:block mobile:hidden">
            <div className="relative h-2 bg-blue-100 rounded-full overflow-hidden">
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-400 rounded-full"
                style={{ scaleX: scrollYProgress, transformOrigin: "0%" }}
              />
            </div>
            <div className="mt-6 flex items-center justify-between text-sm text-blue-600">
              <span>2024</span>
              <span>2025</span>
              <span>2026</span>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Scrolling right section */}
      <div
        ref={scrollContainerRef}
        className="desktop:w-2/3 laptop:w-2/3 tablet:w-full mobile:w-full"
      >
        <div className="space-y-24">
          {milestones.map((milestone, index) => (
            <TimelineMilestone
              key={index}
              milestone={milestone}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
