import React from "react";
import Hero from "../components/Hero";
import NumericTwin from "../components/NumericTwin";
import ScreenedCancers from "../components/ScreenedCancers";
import Sponsors from "../components/Sponsors";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import Quote from "../components/Quote";
import MovemberBanner from "../components/MovemberBanner";
import SurvivalRatesChart from "../components/SurvivalRatesChart";
import { motion } from "framer-motion";

const Home: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      {/* Hero Section with Parallax Effect */}
      <div className="relative">
        <Hero />
        <motion.div
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent"
        />
      </div>{" "}
      {/* Survival Rates Chart with Fade In */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="bg-gradient-to-b from-white via-blue-50/30 to-white"
      >
        <SurvivalRatesChart />
      </motion.div>
      {/* Numeric Twin Section with Floating Animation */}
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <NumericTwin />
      </motion.div>
      {/* Screened Cancers with Stagger Effect */}
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <ScreenedCancers />
      </motion.div>
      {/* Sponsors Section with Hover Effects */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="bg-gradient-to-b from-white via-blue-50/20 to-white"
      >
        <Sponsors />
      </motion.div>
      {/* FAQ Section with Smooth Transitions */}
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <FAQ />
      </motion.div>
      {/* Quote Section with Scale Effect */}
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="bg-gradient-to-b from-white via-blue-50/10 to-white"
      >
        <Quote />
      </motion.div>
      {/* Footer with Slide Up Effect */}
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <Footer />{" "}
      </motion.div>
      <MovemberBanner />
    </motion.div>
  );
};

export default Home;
