import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns: ["translation"],
    supportedLngs: ["fr", "en"],
    fallbackLng: "fr",
    react: {
      useSuspense: false,
    },
    missingKeyHandler: (_lngs, _ns, key, _fallbackValue) => {
      throw new Error(`Translation key "${key}" is missing`);
    },
    saveMissing: true,
  });

export default i18n;
