import { FC } from "react";
import { Header } from "./Header";
import { FeatureList } from "./FeatureList";

export const NumericTwin: FC = () => {
  return (
    <section className="relative w-full bg-white py-24">
      <div className="container mx-auto px-6 max-w-7xl">
        <Header />
        <FeatureList />
      </div>
    </section>
  );
};
