import { FC } from "react";
import { HiUser } from "react-icons/hi2";
import { motion } from "framer-motion";

export const IconButton: FC = () => {
  return (
    <motion.a
      href="https://neok.app"
      target="_blank"
      rel="noopener noreferrer"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="p-2 text-blue-950 hover:text-blue-600 transition-colors"
    >
      <HiUser className="w-6 h-6" />
    </motion.a>
  );
};
