import { FC } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export const LanguageButton: FC = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === "fr" ? "en" : "fr";
    i18n.changeLanguage(newLang);
  };

  return (
    <motion.button
      onClick={toggleLanguage}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="px-3 py-1 text-sm font-medium text-blue-600 bg-blue-100 rounded-lg hover:bg-blue-200 transition-colors"
    >
      {i18n.language === "fr" ? "EN" : "FR"}
    </motion.button>
  );
};
