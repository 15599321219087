import { motion } from "framer-motion";
import { FC } from "react";

export const CTAButtons: FC = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.6 }}
    className="flex flex-row justify-center gap-4 mb-16"
  >
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className="group px-8 py-4 bg-blue-600 text-white rounded-2xl font-medium 
               transition-all duration-300 shadow-lg shadow-blue-600/25
               hover:bg-blue-700 hover:shadow-xl hover:shadow-blue-600/30
               flex items-center justify-center gap-2"
      onClick={() =>
        (window.location.href = "https://neok.app/sign-in#register")
      }
    >
      Commencer le Dépistage
      <svg
        className="w-5 h-5 group-hover:translate-x-1 transition-transform"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
      </svg>
    </motion.button>
  </motion.div>
);
