import { FC } from "react";
import { motion } from "framer-motion";
import { Background } from "../Hero/components/Background";
import { GradientOrbs } from "../Hero/components/GradientOrbs";
import { InfoBox } from "./components/InfoBox";

const MissionHero: FC = () => {
  const infoBoxes = [
    {
      icon: (
        <svg
          className="w-8 h-8 text-blue-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      ),
      title: "Innovation",
      description:
        "Recherche et développement de solutions innovantes pour le dépistage des cancers",
    },
    {
      icon: (
        <svg
          className="w-8 h-8 text-blue-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
      ),
      title: "Vision",
      description:
        "Notre engagement envers le service public et l'amélioration des offres de santé.",
    },
  ];

  return (
    <section className="relative h-screen w-full bg-white overflow-hidden desktop:pt-32 laptop:pt-28 tablet:pt-24 mobile:pt-20">
      <Background />
      <GradientOrbs />
      <div className="container mx-auto px-6 h-full relative z-10 flex items-center">
        <div className="max-w-4xl mx-auto text-center">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="desktop:text-6xl laptop:text-5xl tablet:text-4xl mobile:text-3xl font-bold text-blue-950 mb-8 leading-tight"
          >
            Anticipez pour prévenir le cancer !
          </motion.h1>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="desktop:text-6xl laptop:text-5xl tablet:text-4xl mobile:text-3xl text-blue-600 mb-8"
          >
            Une start-up à mission sociétale
          </motion.h2>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col justify-center items-center gap-8 mt-12"
          >
            {infoBoxes.map((box, index) => (
              <InfoBox
                key={index}
                icon={box.icon}
                title={box.title}
                description={box.description}
              />
            ))}
          </motion.div>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="desktop:text-xl laptop:text-lg tablet:text-base mobile:text-base text-blue-900/70 mt-12"
          >
            Neok s'engage aux côtés de la communauté médicale pour démocratiser
            le dépistage précoce des cancers.
          </motion.p>
        </div>
      </div>
    </section>
  );
};

export default MissionHero;
