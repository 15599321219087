import { FC, useEffect, useState } from "react";
import { motion, MotionValue, useTransform } from "framer-motion";

interface StepsHeaderProps {
  scrollProgress: MotionValue<number>;
}

export const StepsHeader: FC<StepsHeaderProps> = ({ scrollProgress }) => {
  const steps = ["01", "02", "03", "04", "05", "06"];
  const [activeSteps, setActiveSteps] = useState<boolean[]>(
    new Array(steps.length).fill(false),
  );

  // Transform scroll progress (0-1) to step index (0-5)
  const currentStep = useTransform(
    scrollProgress,
    [0, 1],
    [0, steps.length - 1],
  );

  useEffect(() => {
    const unsubscribe = currentStep.on("change", (latest) => {
      const roundedStep = Math.floor(latest);
      setActiveSteps(steps.map((_, index) => index <= roundedStep));
    });

    return () => unsubscribe();
  }, [currentStep, steps.length]);

  return (
    <div className="mb-8">
      {/* Numbers row */}
      <div className="desktop:flex laptop:flex tablet:hidden mobile:hidden justify-between mb-8 desktop:px-6 laptop:px-6">
        {steps.map((step, index) => (
          <motion.div
            key={step}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className={`flex items-center justify-center w-16 h-16 text-3xl font-bold rounded-full border-2 transition-all duration-300 ${
              activeSteps[index]
                ? "text-blue-600 bg-blue-50 border-blue-100 hover:border-blue-200"
                : "text-gray-400 bg-gray-50 border-gray-100 hover:border-gray-200"
            }`}
          >
            {step}
          </motion.div>
        ))}
      </div>

      {/* Progress bar */}
      <div className="desktop:block laptop:block tablet:hidden mobile:hidden relative h-2 bg-gradient-to-r from-blue-50 to-blue-100 rounded-full overflow-hidden shadow-inner">
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-400 rounded-full shadow-lg transition-transform duration-300 ease-out"
          style={{
            scaleX: scrollProgress,
            transformOrigin: "0%",
            filter: "drop-shadow(0 0 0.5rem rgba(37, 99, 235, 0.2))",
          }}
        />
      </div>
    </div>
  );
};
