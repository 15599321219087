import { FC } from "react";
import { motion } from "framer-motion";

export const AnimatedShapes: FC = () => {
  const shapes = [
    { type: "circle", size: "w-24 h-24", delay: 0 },
    { type: "square", size: "w-32 h-32", delay: 0.2 },
    { type: "triangle", size: "w-28 h-28", delay: 0.4 },
  ];

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {shapes.map((shape, index) => (
        <motion.div
          key={index}
          className={`absolute ${shape.size} opacity-10 bg-blue-200 rounded-full`}
          initial={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
            scale: 0,
          }}
          animate={{
            x: [
              Math.random() * window.innerWidth,
              Math.random() * window.innerWidth,
              Math.random() * window.innerWidth,
            ],
            y: [
              Math.random() * window.innerHeight,
              Math.random() * window.innerHeight,
              Math.random() * window.innerHeight,
            ],
            scale: [1, 1.2, 1],
            rotate: [0, 180, 360],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: "reverse",
            delay: shape.delay,
            ease: "linear",
          }}
        />
      ))}
    </div>
  );
};
