import { FC } from "react";
import { motion } from "framer-motion";

const Quote: FC = () => {
  const words = ["Simple", "Efficace", "Complet"];

  return (
    <section className="relative w-full bg-white py-36 overflow-x-hidden">
      <div className="container mx-auto px-6 max-w-7xl py-12">
        <div className="flex justify-center">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center"
          >
            <motion.h2 className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl font-bold text-blue-950">
              {words.map((word, index) => (
                <motion.span
                  key={word}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    delay: index * 0.2,
                    duration: 0.8,
                    type: "spring",
                    stiffness: 100,
                  }}
                  className="desktop:inline-block laptop:inline-block tablet:inline-block mobile:block mobile:my-4"
                >
                  {word}
                  {index < words.length - 1 && (
                    <span className="text-blue-600 desktop:mx-6 laptop:mx-6 tablet:mx-6 mobile:my-2 mobile:block">
                      •
                    </span>
                  )}
                </motion.span>
              ))}
            </motion.h2>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Quote;
