import { motion } from "framer-motion";
import { FC } from "react";

const stats = [
  {
    value: "I/II",
    label: "Stade précoce",
    description:
      "Dépistage des cancers naissants (phase I/II) 6 mois à 2 ans avant l'apparition des premiers signes cliniques.",
  },
  {
    value: "75%",
    label: "des cancers dépistés",
    description:
      "Le panel Neok se veut le plus exhaustif pour obtenir la meilleure sensibilité.",
  },
  {
    value: "4×",
    label: "Guérison",
    description:
      "La détection précoce du cancer (en stade I/II) offre un taux de survie de 86 %, contre 22 % en stade avancé.",
  },
];

export const StatsSection: FC = () => (
  <div className="max-w-4xl mx-auto pt-8">
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.8, duration: 0.8 }}
      className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-6 mt-12 mobile:max-w-[280px] mobile:mx-auto"
    >
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 + index * 0.2 }}
          whileHover={{ scale: 1.02 }}
          className="bg-blue-50/50 backdrop-blur-sm rounded-xl p-6 transition-all duration-300"
        >
          <div className="text-center">
            <motion.div
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ delay: 1.2 + index * 0.2 }}
              className="mb-2"
            >
              <span className="desktop:text-3xl laptop:text-3xl tablet:text-2xl mobile:text-2xl font-bold text-blue-600">
                {stat.value}
              </span>
            </motion.div>
            <h3 className="desktop:text-lg laptop:text-lg tablet:text-base mobile:text-base font-semibold text-blue-950 mb-3">
              {stat.label}
            </h3>
            <p className="desktop:text-sm laptop:text-sm tablet:text-xs mobile:text-xs text-blue-900/70">
              {stat.description}
            </p>
          </div>
        </motion.div>
      ))}
    </motion.div>
  </div>
);
