import { FC } from "react";
import { motion } from "framer-motion";

export const UserActions: FC = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ delay: 0.2 }}
  >
    <h3 className="font-semibold text-blue-950 mb-4">Compte</h3>
    <ul className="space-y-3">
      <li>
        <a
          href="https://neok.app/sign-in#register"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-900/70 hover:text-blue-600 transition-colors"
        >
          S'inscrire
        </a>
      </li>
      <li>
        <a
          href="https://neok.app/sign-in"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-900/70 hover:text-blue-600 transition-colors"
        >
          Se connecter
        </a>
      </li>
    </ul>
  </motion.div>
);
