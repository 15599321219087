import { FC, useEffect, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

export const ParallaxEffect: FC = () => {
  const { scrollY } = useScroll();
  const y1 = useTransform(scrollY, [0, 500], [0, -150]);
  const y2 = useTransform(scrollY, [0, 500], [0, -100]);
  const y3 = useTransform(scrollY, [0, 500], [0, -50]);

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <motion.div
        style={{ y: y1 }}
        className="absolute inset-0 bg-gradient-to-b from-blue-50/20 to-transparent"
      />
      <motion.div
        style={{ y: y2 }}
        className="absolute inset-0 bg-gradient-to-b from-transparent via-blue-50/10 to-transparent"
      />
      <motion.div
        style={{ y: y3 }}
        className="absolute inset-0 bg-gradient-to-b from-transparent to-blue-50/20"
      />
    </div>
  );
};
