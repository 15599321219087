import { FC } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const NavigationLinks: FC = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ delay: 0.1 }}
    className="desktop:mb-0 laptop:mb-0 tablet:mb-8 mobile:mb-8"
  >
    <h3 className="font-semibold text-blue-950 mb-4">Navigation</h3>
    <ul className="space-y-3">
      <li>
        <Link
          to="/"
          className="text-blue-900/70 hover:text-blue-600 transition-colors"
        >
          Accueil
        </Link>
      </li>
      <li>
        <Link
          to="/mission"
          className="text-blue-900/70 hover:text-blue-600 transition-colors"
        >
          Mission
        </Link>
      </li>
      <li>
        <Link
          to="/depistage"
          className="text-blue-900/70 hover:text-blue-600 transition-colors"
        >
          Dépistage Neok
        </Link>
      </li>
      <li>
        <Link
          to="/careers"
          className="text-blue-900/70 hover:text-blue-600 transition-colors"
        >
          Rejoindre l'équipe
        </Link>
      </li>
    </ul>
  </motion.div>
);
