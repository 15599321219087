import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import EasterEgg from "./components/EasterEgg";
import Home from "./pages/Home";
import Mission from "./pages/Mission";
import Screening from "./pages/Screening";
import Legal from "./pages/Legal";
import Careers from "./pages/Careers";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <EasterEgg />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/screening" element={<Screening />} />
        <Route path="/login" element={<div>Login Page</div>} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/careers" element={<Careers />} />
      </Routes>
    </Router>
  );
}

export default App;
