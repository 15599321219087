export interface CancerType {
  name: string;
  bloodMarkers: number;
  clinicalMarkers: number;
  doublingTime: string;
  percentageCases: string;
  mortalityRate: string;
}

export const cancerTypes: CancerType[] = [
  {
    name: "Lymphomes",
    bloodMarkers: 14,
    clinicalMarkers: 11,
    doublingTime: "30-40 jours",
    percentageCases: "4.2% des cas de cancer",
    mortalityRate: "30% de mortalité à 5 ans",
  },
  {
    name: "Œsophage",
    bloodMarkers: 4,
    clinicalMarkers: 9,
    doublingTime: "90-120 jours",
    percentageCases: "1.1% des cas de cancer",
    mortalityRate: "85% de mortalité à 5 ans",
  },
  {
    name: "Sein",
    bloodMarkers: 8,
    clinicalMarkers: 12,
    doublingTime: "100-200 jours",
    percentageCases: "15.5% des cas de cancer",
    mortalityRate: "12% de mortalité à 5 ans",
  },
  {
    name: "Foie",
    bloodMarkers: 3,
    clinicalMarkers: 8,
    doublingTime: "60-120 jours",
    percentageCases: "2.3% des cas de cancer",
    mortalityRate: "82% de mortalité à 5 ans",
  },
  {
    name: "Pancréas",
    bloodMarkers: 7,
    clinicalMarkers: 15,
    doublingTime: "40-60 jours",
    percentageCases: "3.2% des cas de cancer",
    mortalityRate: "91% de mortalité à 5 ans",
  },
  {
    name: "Leucémie",
    bloodMarkers: 9,
    clinicalMarkers: 25,
    doublingTime: "2-4 jours",
    percentageCases: "2.8% des cas de cancer",
    mortalityRate: "45% de mortalité à 5 ans",
  },
  {
    name: "Ovaires",
    bloodMarkers: 10,
    clinicalMarkers: 3,
    doublingTime: "60-90 jours",
    percentageCases: "1.8% des cas de cancer",
    mortalityRate: "55% de mortalité à 5 ans",
  },
  {
    name: "Thyroïde",
    bloodMarkers: 6,
    clinicalMarkers: 9,
    doublingTime: "180-400 jours",
    percentageCases: "3.1% des cas de cancer",
    mortalityRate: "2% de mortalité à 5 ans",
  },
  {
    name: "Poumon",
    bloodMarkers: 12,
    clinicalMarkers: 13,
    doublingTime: "90-180 jours",
    percentageCases: "11.6% des cas de cancer",
    mortalityRate: "82% de mortalité à 5 ans",
  },
  {
    name: "Myélome",
    bloodMarkers: 14,
    clinicalMarkers: 1,
    doublingTime: "30-60 jours",
    percentageCases: "1.8% des cas de cancer",
    mortalityRate: "54% de mortalité à 5 ans",
  },
  {
    name: "Gastro-intestinal",
    bloodMarkers: 10,
    clinicalMarkers: 16,
    doublingTime: "130-260 jours",
    percentageCases: "6.8% des cas de cancer",
    mortalityRate: "68% de mortalité à 5 ans",
  },
  {
    name: "Colon-rectum",
    bloodMarkers: 10,
    clinicalMarkers: 19,
    doublingTime: "130-200 jours",
    percentageCases: "11.7% des cas de cancer",
    mortalityRate: "35% de mortalité à 5 ans",
  },
  {
    name: "Prostate",
    bloodMarkers: 5,
    clinicalMarkers: 12,
    doublingTime: "240-600 jours",
    percentageCases: "13.5% des cas de cancer",
    mortalityRate: "8% de mortalité à 5 ans",
  },
  {
    name: "Col de l'utérus",
    bloodMarkers: 9,
    clinicalMarkers: 9,
    doublingTime: "45-60 jours",
    percentageCases: "3.2% des cas de cancer",
    mortalityRate: "7.5% de mortalité à 5 ans",
  },
];
