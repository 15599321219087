import { FC } from "react";
import { LeftContent } from "./LeftContent";
import { RightContent } from "./RightContent";

export const HeroContent: FC = () => (
  <div className="desktop:flex laptop:flex tablet:block mobile:block items-center justify-between gap-16 max-w-6xl mx-auto">
    <LeftContent />
    <RightContent />
  </div>
);
