import { motion, AnimatePresence } from "framer-motion";
import { FC, useEffect, useState } from "react";

export const StatsCard: FC = () => {
  const [showNewValues, setShowNewValues] = useState(false);

  useEffect(() => {
    // Start animation after component mount
    const timer = setTimeout(() => {
      setShowNewValues(true);
    }, 2000); // Wait 2 seconds before starting the transition

    return () => clearTimeout(timer);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.8 }}
      className="absolute -bottom-8 -right-8 bg-white/90 p-6 rounded-2xl shadow-xl backdrop-blur-sm border border-blue-50"
    >
      <div className="flex items-center gap-8">
        <div className="text-center relative">
          <AnimatePresence mode="wait">
            {!showNewValues ? (
              <motion.div
                key="old-panel"
                initial={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="absolute inset-0"
              >
                <div className="text-4xl font-bold text-blue-600">1.2</div>
                <div className="text-sm text-blue-600/70 mt-1">
                  Version du panel
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="new-panel"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.5,
                  type: "spring",
                  stiffness: 100,
                }}
                className="absolute inset-0"
              >
                <motion.div
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  className="text-4xl font-bold text-blue-600"
                >
                  1.3
                </motion.div>
                <div className="text-sm text-blue-600/70 mt-1">
                  Version du panel
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          {/* Spacer div to maintain container height */}
          <div className="opacity-0">
            <div className="text-4xl font-bold">1.3</div>
            <div className="text-sm mt-1">Version du panel</div>
          </div>
        </div>

        <div className="h-12 w-px bg-blue-100"></div>

        <div className="text-center relative">
          <AnimatePresence mode="wait">
            {!showNewValues ? (
              <motion.div
                key="old-families"
                initial={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="absolute inset-0"
              >
                <div className="text-4xl font-bold text-blue-600">11</div>
                <div className="text-sm text-blue-600/70 mt-1">
                  Familles de cancers
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="new-families"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.5,
                  type: "spring",
                  stiffness: 100,
                }}
                className="absolute inset-0"
              >
                <motion.div
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  className="text-4xl font-bold text-blue-600"
                >
                  14
                </motion.div>
                <div className="text-sm text-blue-600/70 mt-1">
                  Familles de cancers
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          {/* Spacer div to maintain container height */}
          <div className="opacity-0">
            <div className="text-4xl font-bold">14</div>
            <div className="text-sm mt-1">Familles de cancers</div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
