import { FC } from "react";
import { motion } from "framer-motion";

export const LogoSection: FC = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="desktop:mb-0 laptop:mb-0 tablet:mb-8 mobile:mb-8"
  >
    <img
      src="https://createsiteai.b-cdn.net/c/_/94e13579-4863-4bd5-99f4-e25085f6d291"
      alt="NEOK Logo"
      className="h-12 mb-4"
    />
    <p className="text-blue-900/70">
      Acteur de prévention
      <br />
      en cancérologie
    </p>
  </motion.div>
);
