import { FC } from "react";
import { TimelineContent } from "./components/TimelineContent";

export const Timeline: FC = () => {
  return (
    <section className="relative w-full bg-white py-24 min-h-screen">
      <div className="container mx-auto px-6">
        <TimelineContent />
      </div>
    </section>
  );
};

export default Timeline;
