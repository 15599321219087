export const milestones = [
  {
    title: "Lancer mon dépistage",
    description: "Création d'un panel",
    details:
      "Développement par les oncologues de Neok d'un panel précis de biomarqueurs, enrichi en temps réel des innovations scientifiques.",
  },
  {
    title: "Déployer Neok dans les hôpitaux",
    description: "Cohortes de patients à haut risque",
    details:
      "En 2025, les cohortes de patients à haut risque seront suivis avec le panel Neok pour améliorer la compréhension du développement de leur maladie.",
  },
  {
    title: "Construction d'une IA propriétaire",
    description: "Recherches pionnières",
    details:
      "Grâce à cette cohorte hospitalière, des recherches pionnières sur la prévalence des biomarqueurs verront le jour.",
  },
  {
    title: "Amélioration continue du panel Neok",
    description: "Innovation et sensibilité",
    details:
      "Neok implémentera ses panels au fur et à mesure des innovations afin d'augmenter sans cesse la sensibilité et la spécificité de ses dépistages.",
  },
  {
    title: "Démocratiser la médecine préventive oncologique",
    description: "Engagement public",
    details:
      "Neok s'engage dans la lutte contre le cancer aussi bien par la recherche menée par ses équipes que par le déploiement d'un service grand public.",
  },
];
