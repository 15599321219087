import { FC } from "react";
import {
  BottomSection,
  LogoSection,
  NavigationLinks,
  UserActions,
} from "./components";

export const Footer: FC = () => {
  return (
    <footer className="bg-white border-t border-blue-100">
      <div className="container mx-auto px-6 max-w-7xl py-16">
        <div className="desktop:flex laptop:flex tablet:block mobile:block justify-between items-start mb-12">
          <LogoSection />
          <NavigationLinks />
          <UserActions />
        </div>
        <BottomSection />
      </div>
    </footer>
  );
};
