import { FC } from "react";

import { motion } from "framer-motion";

export const BottomSection: FC = () => (
  <motion.div
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    transition={{ delay: 0.3 }}
    className="pt-8 border-t border-blue-100"
  >
    <div className="desktop:flex laptop:flex tablet:block mobile:block justify-between items-center">
      <p className="text-blue-900/70 desktop:mb-0 laptop:mb-0 tablet:mb-4 mobile:mb-4">
        © 2024 NEOK. Tous droits réservés.
      </p>
      <div className="flex gap-4">
        <a
          href="https://drive.google.com/file/d/1wehvYdrP6Ab8Bo4srPOmR6AzSyuKx94M/view?usp=drive_link"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-900/70 hover:text-blue-600 transition-colors"
        >
          Avis de confidentialité
        </a>
        <a
          href="https://drive.google.com/file/d/1WaEjAh2jkp6v-KA5UZ6h3iw3hcpQilg3/view?usp=drive_link"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-900/70 hover:text-blue-600 transition-colors"
        >
          Mentions légales
        </a>
      </div>
    </div>
  </motion.div>
);
