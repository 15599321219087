import { FC, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { StepsContent } from "./components/StepsContent";
import { StepsHeader } from "./components/StepsHeader";

export const ScreeningSteps: FC = () => {
  const sectionRef = useRef<HTMLElement>(null);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["75% end", "start start"],
  });

  return (
    <section ref={sectionRef} className="relative w-full bg-white py-24">
      <div className="container mx-auto px-6">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-blue-950 mb-6 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
            Les différentes étapes
          </h2>
        </motion.div>
        <div className="relative">
          <StepsHeader scrollProgress={scrollYProgress} />
          <StepsContent />
        </div>
      </div>
    </section>
  );
};

export default ScreeningSteps;
