import { FC, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { IconButton } from "./components/IconButton";
import { MobileMenu } from "./components/MobileMenu";
import { LanguageButton } from "./components/LanguageButton";

interface NavLink {
  path: string;
  label: string;
  isButton?: boolean;
}

export const Navbar: FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navLinks: NavLink[] = [
    { path: "/", label: "Accueil" },
    { path: "/mission", label: "Mission" },
    { path: "/screening", label: "Dépistage", isButton: true },
  ];

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? "bg-white shadow-lg" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto px-6 max-w-7xl">
        <div className="flex items-center justify-between min-h-[5rem] h-20">
          <Link to="/" className="flex items-center">
            <div className="flex items-center gap-3">
              <img
                src="https://createsiteai.b-cdn.net/c/_/94e13579-4863-4bd5-99f4-e25085f6d291"
                alt="NEOK Logo"
                className="h-10"
              />
              <span className="text-2xl font-bold text-blue-950">Neok</span>
            </div>
          </Link>
          <div className="hidden desktop:flex laptop:flex tablet:flex mobile:hidden items-center space-x-8">
            {navLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={`text-lg font-medium transition-all duration-300 ${
                  link.isButton
                    ? location.pathname === "/screening"
                      ? "px-4 py-1.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                      : "px-4 py-1.5 bg-blue-100 text-blue-600 rounded-lg hover:bg-blue-200 transition-colors"
                    : link.path === "/"
                      ? location.pathname === "/"
                        ? "text-blue-600"
                        : "text-black hover:text-black/80"
                      : location.pathname === link.path
                        ? "text-blue-600"
                        : "text-blue-950 hover:text-blue-600"
                }`}
              >
                {link.label}
              </Link>
            ))}
          </div>
          <div className="flex items-center gap-4">
            <div className="hidden">
              <LanguageButton />
            </div>
            <IconButton />
            <MobileMenu navLinks={navLinks} />
          </div>
        </div>
      </div>
    </motion.nav>
  );
};
