import { FC } from "react";
import { motion } from "framer-motion";

interface InfoBoxProps {
  icon: JSX.Element;
  title: string;
  description: string;
}

export const InfoBox: FC<InfoBoxProps> = ({ icon, title, description }) => {
  // Enforce character limits
  const truncatedTitle = title.slice(0, 20); // 20 chars max for title
  const truncatedDescription = description.slice(0, 80); // 80 chars max for description

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="relative w-[300px] bg-white rounded-xl border border-blue-100 hover:border-blue-200 transition-all duration-300"
    >
      {/* Floating icon */}
      <div className="absolute -left-3 top-4 bg-blue-50 p-3 rounded-xl border border-blue-100 shadow-sm">
        {icon}
      </div>

      {/* Content with increased left padding to accommodate the floating icon */}
      <div className="pl-12 pr-6 py-4">
        <h4 className="text-lg font-semibold text-blue-950 mb-2">
          {truncatedTitle}
        </h4>
        <p className="text-sm text-blue-900/70">{truncatedDescription}</p>
      </div>
    </motion.div>
  );
};
