import { FC } from "react";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import {
  HiOutlineBriefcase,
  HiOutlineHeart,
  HiOutlineLightBulb,
} from "react-icons/hi";

const positions = [
  {
    title: "Développeur Frontend React",
    department: "Tech",
    location: "Paris, France",
    type: "CDI",
    description:
      "Rejoignez notre équipe pour développer des interfaces utilisateur innovantes avec React. Vous participerez à la création d'applications web performantes pour la détection précoce du cancer. Technologies : React, TypeScript, Tailwind CSS, Git. Expérience : 3+ ans en développement frontend.",
  },
  {
    title: "Développeur Full Stack",
    department: "Tech",
    location: "Paris, France",
    type: "CDI",
    description:
      "Intégrez notre équipe technique pour développer des solutions innovantes de bout en bout. Vous travaillerez sur l'architecture, le développement et le déploiement de nos applications. Technologies : Node.js, React, PostgreSQL, AWS. Expérience : 5+ ans en développement full stack.",
  },
  {
    title: "Data Scientist",
    department: "R&D",
    location: "Paris, France",
    type: "CDI",
    description:
      "Rejoignez notre équipe R&D pour analyser les données biomédicales et améliorer nos algorithmes de détection. Vous travaillerez sur des modèles d'apprentissage automatique et collaborerez avec nos oncologues. Technologies : Python, TensorFlow, SQL. Expérience : 4+ ans en data science.",
  },
  {
    title: "Chef de Projet Clinical",
    department: "Médical",
    location: "Paris, France",
    type: "CDI",
    description:
      "Pilotez nos études cliniques en collaboration avec les centres hospitaliers. Vous superviserez la collecte de données, assurerez la conformité réglementaire et coordonnerez les équipes médicales. Formation : Bac+5 en recherche clinique. Expérience : 5+ ans en gestion d'études cliniques.",
  },
  {
    title: "Commercial B2B",
    department: "Commercial",
    location: "Paris, France",
    type: "CDI",
    description:
      "Développez notre portefeuille clients en prospectant et fidélisant les entreprises et institutions de santé. Vous présenterez nos solutions innovantes et établirez des partenariats stratégiques. Formation commerciale requise. Expérience : 3+ ans en vente B2B secteur santé.",
  },
];

const values = [
  {
    icon: <HiOutlineHeart className="w-8 h-8 text-blue-600" />,
    title: "Impact Social",
    description:
      "Participez à notre révolution médicale en développant des technologies de détection précoce du cancer qui sauvent des vies chaque jour.",
  },
  {
    icon: <HiOutlineLightBulb className="w-8 h-8 text-blue-600" />,
    title: "Innovation",
    description:
      "Plongez dans l'avenir de la médecine en créant des solutions technologiques de pointe qui redéfinissent les standards des soins de santé.",
  },
  {
    icon: <HiOutlineBriefcase className="w-8 h-8 text-blue-600" />,
    title: "Startup",
    description:
      "Intégrez une équipe dynamique et bénéficiez d'un environnement stimulant où votre expertise contribuera directement à notre croissance.",
  },
];

const Careers: FC = () => {
  const handleApply = (position: string) => {
    const email =
      position === "Commercial B2B"
        ? "arthur.seres@neok.fr"
        : "arthus.carpentier@neok.fr";
    window.location.href = `mailto:${email}?subject=Candidature pour le poste de ${position}`;
  };

  return (
    <>
      <section className="relative min-h-screen w-full bg-white overflow-hidden desktop:pt-32 laptop:pt-28 tablet:pt-24 mobile:pt-20">
        <div className="container mx-auto px-6 max-w-7xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h1 className="desktop:text-6xl laptop:text-5xl tablet:text-4xl mobile:text-3xl font-bold text-blue-950 mb-6">
              REJOIGNEZ NOTRE MISSION
            </h1>
            <p className="text-blue-900/70 max-w-2xl mx-auto text-xl">
              Ensemble, révolutionnons la détection précoce du cancer
            </p>
          </motion.div>

          <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-8 mb-24">
            {values.map((value, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-white p-6 rounded-xl border border-blue-100 hover:border-blue-200 transition-all duration-300 shadow-sm hover:shadow-md"
              >
                <div className="mb-4">{value.icon}</div>
                <h3 className="text-xl font-bold text-blue-950 mb-2">
                  {value.title}
                </h3>
                <p className="text-blue-900/70">{value.description}</p>
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="mb-12"
          >
            <h2 className="text-3xl font-bold text-blue-950 mb-8">
              Postes Ouverts
            </h2>
            <div className="space-y-6">
              {positions.map((position, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white p-6 rounded-xl border border-blue-100 hover:border-blue-200 transition-all duration-300 shadow-sm hover:shadow-md"
                >
                  <div className="flex items-start justify-between desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col gap-4">
                    <div>
                      <div className="flex items-center gap-2 mb-2">
                        <HiOutlineBriefcase className="w-5 h-5 text-blue-600" />
                        <h3 className="text-xl font-bold text-blue-950">
                          {position.title}
                        </h3>
                      </div>
                      <p className="text-blue-900/70 mb-4">
                        {position.description}
                      </p>
                      <div className="flex gap-4">
                        <span className="text-sm bg-blue-50 text-blue-600 px-3 py-1 rounded-full">
                          {position.department}
                        </span>
                        <span className="text-sm bg-blue-50 text-blue-600 px-3 py-1 rounded-full">
                          {position.location}
                        </span>
                        <span className="text-sm bg-blue-50 text-blue-600 px-3 py-1 rounded-full">
                          {position.type}
                        </span>
                      </div>
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors whitespace-nowrap"
                      onClick={() => handleApply(position.title)}
                    >
                      Postuler
                    </motion.button>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Careers;
