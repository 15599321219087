import { FC } from "react";
import MissionHero from "../components/MissionHero";
import Timeline from "../components/Timeline";
import Footer from "../components/Footer";
import Quote from "../components/Quote";
import { motion } from "framer-motion";

const Mission: FC = () => {
  return (
    <>
      <MissionHero />
      {/* Vision Section with Image */}
      <section className="relative w-full bg-white py-24 overflow-x-hidden">
        <div className="container mx-auto px-6">
          <div className="desktop:flex laptop:flex tablet:block mobile:block items-center gap-12">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full"
            >
              <img
                src="https://createsiteai.b-cdn.net/c/_/37e97b19-daaf-4c2e-a901-7c2430781331"
                alt="Medical Research"
                className="w-full h-[400px] object-cover rounded-2xl shadow-lg"
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full desktop:mt-0 laptop:mt-0 tablet:mt-8 mobile:mt-8"
            >
              <img
                src="https://createsiteai.b-cdn.net/c/_/87d3c7e4-0e93-451a-af74-d0574e5af5fc"
                alt="Medical Technology"
                className="w-full h-[400px] object-cover rounded-2xl shadow-lg"
              />
            </motion.div>
          </div>
        </div>
      </section>
      <Timeline />
      {/* Impact Section with Image */}
      <section className="relative w-full bg-white py-24 overflow-x-hidden">
        <div className="container mx-auto px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="max-w-4xl mx-auto"
          >
            <img
              src="https://createsiteai.b-cdn.net/c/_/e5baa829-1079-4185-8e93-94d36f91a1f1"
              alt="Medical Team Collaboration"
              className="w-full h-[500px] object-cover rounded-2xl shadow-lg mb-24"
            />
          </motion.div>
        </div>
      </section>
      <Quote />
      <Footer />
    </>
  );
};

export default Mission;
